<template>
	<div class="main-content">
		<ktv-breadcrumb title="Category" page="Category" folder="Training" />
		<ktv-table
			id="categorylist"
			:columns="columns"
			:is-loading="isLoading"
			:line-numbers="true"
			:rows="mainData.lists"
			:total-rows="mainData.total"
			:mode="modeTable"
			:filter="false"
			:search-custom-enabled="true"
			:select-options="{
				enabled: false,
				selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
				selectionInfoClass: `custom-class`,
				selectionText: `rows selected`,
				clearSelectionText: `clear`,
				disableSelectInfo: true, // disable the select info panel on top
				selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
			}"
			@on-column-filter="onColumnFilter"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearchFilter"
			@on-sort-change="onSortChange"
		>
			<template #actionleft>
				<ktv-button text="Add Category" icon="add" color="green-light" @click="openFormCategory('add')" />
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field == 'button'">
					<b-dropdown
						size="xs"
						variant="link"
						toggle-class="text-decoration-none"
						no-caret
					>
						<template #button-content>
							<span class="btndropdownaction">
								<img :src="icondropdown">
							</span>
						</template>
						<b-dropdown-item class="dropdownitem-action" @click="openFormCategory(props.row)">
							<img :src="iconedit" class="imgicon"> Edit & Detail
						</b-dropdown-item>
						<b-dropdown-item class="dropdownitem-action" @click="deleteCategory(props.row)">
							<img :src="icondelete" class="imgicon"> Delete
						</b-dropdown-item>
					</b-dropdown>
				</span>
			</template>
		</ktv-table>
		<ktv-modal v-model="formCategory" size="lg" :title="titleCategoryModal" no-close-on-backdrop no-close-on-esc @close="closeFormCategory()">
			<template #content>
				<h4 class="pl-3 mb-2">Main Category</h4>
				<b-col cols="12">
					<b-row v-for="(v, i) in languages" :key="i" class="ml-3 form-group" role="group">
						<b-col md="3">
							<span>{{ v.language_desc }}</span>
						</b-col>
						<b-col md="9">
							<b-form-input
								v-model="form.mainCategories[i].languageText"
								v-validate="v.language_desc === 'Indonesia' ? 'required' : ''"
								:name="v.language_desc"
								:state="validateState(v.language_desc)"
								type="text"
								placeholder="..."
							/>
							<b-form-invalid-feedback>
								{{ veeErrors.first(v.language_desc) }}
							</b-form-invalid-feedback>
						</b-col>
					</b-row>
				</b-col>
			</template>
			<template #footer>
				<ktv-button color="primary" size="sm" class="float-right" text="Save" @click="saveCategory(form)" />
				<ktv-button color="light" size="sm" class="float-right mr-2" text="Cancel" @click="closeFormCategory()" />
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { KtvBreadcrumb, KtvModal, KtvTable, KtvButton } from "@/components"
	export default {
		metaInfo: {
			title: "Category",
		},
		components: { KtvBreadcrumb, KtvModal, KtvTable, KtvButton },
		data() {
			return {
				/* eslint-disable global-require */
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				isLoading: false,
				columns: [
					{
						label: "Action",
						field: "button",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
					},
					{
						label: "Text Category Indonesia",
						field: "Indonesia.title",
						thClass: "text-left",
					},
					{
						label: "Text Category English",
						field: "English.title",
						thClass: "text-left",
					},
				],
				mainData: {
					lists: [],
					total: 0,
				},
				form: {
					mainCategories: [],
				},
				formCategory: false,
				languages: [],

				modeTable: "remote",
				serverParams: {
					rowPerpage: 10,
					currentPage: 1,
					columnFilters: { CategoryName: "" },
					sort: [
						{
							field: "",
							type: "ASC",
						},
					],
				},
				action: "",
			}
		},
		computed: {
			titleCategoryModal() {
				return "Add Category"
			},
		},
		watch: {
			languages: {
				immediate: true,
				handler: function (v) {
					this.form.mainCategories = []
					v.forEach((v2) => {
						this.form.mainCategories.push({
							languageCode: v2.language_code,
							languageText: "",
							languangeDesc: v2.language_desc,
						})
					})
				},
			},
		},
		mounted() {
			this.getGridMainData()
			this.getLanguages()
		},
		methods: {
			getGridMainData() {
				this.isLoading = true
				this.$http
					.Post(this.serverParams, this.$urlApi.training.category.getList)
					.then((r) => {
						if (r.success) {
							this.mainData.lists = r.results.data.map((a) =>
								a.language_data.reduce(
									(obj, item) =>
										Object.assign(obj, {
											[item.language_desc]: {
												title: item.CategoryLanguageText,
												languangeCode: item.CategoryLanguageCode,
											},
											categoryId: a.CategoryId,
										}),
									{},
								),
							)
							this.mainData.total = r.results.total
						} else {
							this.$swal("Error!", r.error_massage, "error")
						}
						this.isLoading = false
					})
					.catch((e) => {
						this.isLoading = false
						this.$swal("Error!", e.response.data.error_message, "error")
					})
			},
			showLoadingMessage(message) {
				this.$swal.fire({
					title: `${message}`,
					animation: true,
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
					},
				})
			},
			openFormCategory(v) {
				this.action = v
				this.categoryId = v.categoryId
				this.formCategory = true
				if (!this.languages.length) {
					this.getLanguages()
				}
				this.form.mainCategories.forEach((v2, i) => {
					if (v !== "add") {
						this.form.mainCategories[i].languageText = ""
						if (v[v2.languangeDesc]) {
							this.form.mainCategories[i].languageText = v[v2.languangeDesc].title
						}
					} else {
						this.form.mainCategories[i].languageText = ""
					}
				})
			},
			closeFormCategory() {
				this.formCategory = false
			},
			saveCategory(data) {
				this.$validator.validateAll().then((result) => {
					if (!result) {
						return
					}
					if (this.action === "add") {
						this.createCategory(data)
					} else {
						this.updateCategory(data)
					}
				})
			},
			getLanguages() {
				this.showLoadingMessage("Loading languages data...")
				this.$http
					.Get({}, this.$urlApi.training.language)
					.then((r) => {
						this.$swal.closeModal()
						if (r.success) {
							this.languages = r.results
							this.languages.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
						} else {
							this.$swal("Error!", r.error_massage, "error")
						}
					})
					.catch((e) => {
						this.$swal.closeModal()
						this.$swal("Error!", e.response.data.message, "error")
					})
			},
			createCategory(data) {
				let dataToPost = data.mainCategories.filter((item) => !(item.languageText === ""))
				this.showLoadingMessage("Saving Data...")
				let payload = {
					categoryLanguage: dataToPost,
				}
				this.$http
					.Post(payload, this.$urlApi.training.category.create)
					.then((response) => {
						if (response.success) {
							this.$swal.closeModal()
							this.$swal("Saved!", "Your data has been added.", "success")
							this.getGridMainData()
						}
					})
					.catch((e) => {
						this.$swal.closeModal()
						this.$swal("Failed!", e.response.data.message, "error")
					})
			},
			updateCategory(data) {
				let dataToPost = data.mainCategories.filter((item) => !(item.languageText === ""))
				this.showLoadingMessage("Updating Data...")
				let payload = {
					categoryId: this.categoryId,
					categoryLanguage: dataToPost,
				}
				this.$http
					.Put(payload, this.$urlApi.training.category.update)
					.then((response) => {
						if (response.success) {
							this.$swal.closeModal()
							this.$swal("Saved!", "Your data has been updated.", "success")
							this.getGridMainData()
						}
					})
					.catch((e) => {
						this.$swal.closeModal()
						this.$swal("Failed!", e.response.data.message, "error")
					})
			},
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange(params) {
				this.updateParams({ currentPage: params.currentPage })
				this.getGridMainData()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.getGridMainData()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1,
				})
				this.getGridMainData()
			},
			onColumnFilter(params) {
				this.updateParams(params)
				this.getGridMainData()
			},
			onSearchFilter(event) {
				this.updateParams({
					columnFilters: { CategoryName: event },
				})
				this.getGridMainData()
			},
			deleteCategory(item) {
				this.$swal({
					title: "Are you sure?",
					text: "Data will be deleted",
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes",
				}).then((result) => {
					if (result.value) {
						this.showLoadingMessage("Deleting Data...")
						let payload = {
							categoryId: item.categoryId,
						}
						this.$http
							.Delete(payload, this.$urlApi.training.category.delete)
							.then((response) => {
								if (response.success) {
									this.getGridMainData()
									this.$swal.closeModal()
									this.$swal("Deleted!", "Your data has been deleted.", "success")
								} else {
									this.$swal.closeModal()
									this.$swal("Error!", "process error", "error")
								}
							})
							.catch((error) => {
								this.$swal("Failed!", error.response.data.error_message, "error")
								// this.$swal("Error!", "process error", "error");
							})
					}
				})
			},
		},
	}
</script>
